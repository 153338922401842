import React, { createContext, useState } from 'react'

const QuickBuyContext = createContext({
  item: '',
})

export const QuickBuyProvider = ({ children }) => {
  const [quickBuyData, setQuickBuyData] = useState(null)
  const [addToBagClicked, setAddToBagClicked] = useState(null)
  const [showBag, setShowBag] = useState(false)
  const [showReviews, setShowReviews] = useState(false)
  const [showWriteReview, setShowWriteReview] = useState(false)
  const [isAddToCart, setIsAddToCart] = useState(false)

  return (
    <QuickBuyContext.Provider
      value={{
        quickBuyData,
        setQuickBuyData,
        addToBagClicked,
        setAddToBagClicked,
        showBag,
        setShowBag,
        showReviews,
        setShowReviews,
        showWriteReview, 
        setShowWriteReview,
        isAddToCart,
        setIsAddToCart
      }}
    >
      {children}
    </QuickBuyContext.Provider>
  );
}

export default QuickBuyContext