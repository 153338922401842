import React, { createContext, useState, useEffect } from "react";

export const ProductContext = createContext([]);

export const AllProductsProvider = ({ children }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [allCollections, setAllCollections] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch("/all-products.json");
      const data = await response.json();
      setAllProducts(data);

      const response2 = await fetch("/all-collections.json");
      const collections = await response2.json();
      setAllCollections(collections);
    };

    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider value={{allProducts, allCollections}}>
      {children}
    </ProductContext.Provider>
  );
};