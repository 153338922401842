module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blackbough Swim","short_name":"blackbough","icon":"src/image/favicon/android-chrome-512x512.png","icons":[{"src":"src/image/favicon/icon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/image/favicon/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/image/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/image/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9456e4e6e164e4adf92da8b61a6f6f4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
