import React, { createContext, useState, useEffect } from "react";
import './src/styles/global.css';
import 'swiper/swiper-bundle.css';
import "swiper/css"
import "swiper/css/pagination"
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'

import { QuickBuyProvider } from './src/context/QuickBuyContext.jsx'
import { StoreProvider } from './src/context/StoreContext.jsx'
import { AllProductsProvider } from './src/context/AllProductsContext.jsx'

//import { ApolloProvider } from '@apollo/client';
//import client from './src/context/apollo';


export const wrapRootElement = ({ element }) => (
    <AllProductsProvider>
        <QuickBuyProvider>
            <StoreProvider>{element}</StoreProvider>
        </QuickBuyProvider>
    </AllProductsProvider>
    //<ApolloProvider client={client}></ApolloProvider>
)