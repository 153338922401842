exports.components = {
  "component---src-content-parts-blog-page-jsx": () => import("./../../../src/content/parts/BlogPage.jsx" /* webpackChunkName: "component---src-content-parts-blog-page-jsx" */),
  "component---src-content-parts-collections-page-jsx": () => import("./../../../src/content/parts/CollectionsPage.jsx" /* webpackChunkName: "component---src-content-parts-collections-page-jsx" */),
  "component---src-content-parts-explore-collections-page-jsx": () => import("./../../../src/content/parts/ExploreCollectionsPage.jsx" /* webpackChunkName: "component---src-content-parts-explore-collections-page-jsx" */),
  "component---src-content-parts-home-jsx": () => import("./../../../src/content/parts/home.jsx" /* webpackChunkName: "component---src-content-parts-home-jsx" */),
  "component---src-content-parts-lookbook-page-jsx": () => import("./../../../src/content/parts/LookbookPage.jsx" /* webpackChunkName: "component---src-content-parts-lookbook-page-jsx" */),
  "component---src-content-parts-page-content-jsx": () => import("./../../../src/content/parts/PageContent.jsx" /* webpackChunkName: "component---src-content-parts-page-content-jsx" */),
  "component---src-content-parts-press-page-jsx": () => import("./../../../src/content/parts/PressPage.jsx" /* webpackChunkName: "component---src-content-parts-press-page-jsx" */),
  "component---src-content-parts-products-jsx": () => import("./../../../src/content/parts/products.jsx" /* webpackChunkName: "component---src-content-parts-products-jsx" */),
  "component---src-content-parts-search-page-jsx": () => import("./../../../src/content/parts/SearchPage.jsx" /* webpackChunkName: "component---src-content-parts-search-page-jsx" */),
  "component---src-content-parts-single-product-page-jsx": () => import("./../../../src/content/parts/SingleProductPage.jsx" /* webpackChunkName: "component---src-content-parts-single-product-page-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blackbough-beach-club-js": () => import("./../../../src/pages/blackbough-beach-club.js" /* webpackChunkName: "component---src-pages-blackbough-beach-club-js" */),
  "component---src-pages-blackbough-reviews-js": () => import("./../../../src/pages/blackbough-reviews.js" /* webpackChunkName: "component---src-pages-blackbough-reviews-js" */),
  "component---src-pages-blackbough-size-guide-js": () => import("./../../../src/pages/blackbough-size-guide.js" /* webpackChunkName: "component---src-pages-blackbough-size-guide-js" */),
  "component---src-pages-blog-blogpost-js": () => import("./../../../src/pages/blog/blogpost.js" /* webpackChunkName: "component---src-pages-blog-blogpost-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lookbook-js": () => import("./../../../src/pages/lookbook.js" /* webpackChunkName: "component---src-pages-lookbook-js" */),
  "component---src-pages-our-commitment-js": () => import("./../../../src/pages/our-commitment.js" /* webpackChunkName: "component---src-pages-our-commitment-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shipping-and-order-information-js": () => import("./../../../src/pages/shipping-and-order-information.js" /* webpackChunkName: "component---src-pages-shipping-and-order-information-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

